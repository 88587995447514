/* !
 * jQuery index v1.0.0
 * Copyright 2020, Connecty Inc.
 */
(function($, SLSD){
	const a = 'is-active';
	const v = 'is-visible';

	$('.idx-main-slide').slick({
		infinite: true,
		speed: 1500,
		autoplay: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		arrows: false,
		dots: true,
	});

})(window.jQuery, window.SLSD);
